.wrap-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.wrap-pagination {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}
.wrap-filters {
	display: flex;
	margin-bottom: 40px;
	justify-content: space-between;
	.search-input {
		width: 300px;
	}
}

.infinite-scroll-end-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
}

@media (max-width: 966px) {
	.wrap-filters {
		flex-direction: column;
		.search-input {
			width: 100%;
			margin-bottom: 20px;
		}
	}
}