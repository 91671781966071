.item-card {
	display: inline-block;
	margin-bottom: 20px;
	box-shadow: .8px .9px 3px grey;
	transition: all 300ms linear;
	border-radius: 5px;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.item-img {
		height: 100%;
		display: flex;
		align-items: center;
		object-fit: cover;
		border-radius: 5px 5px 0 0;
		max-height: 100% !important;
	}
	.item-vote {
		border-radius: 0 0 5px 5px;
		background: black;
		color: #6366f1;
		text-align: center;
		padding: 0.5rem;
	}

	&:hover {
		box-shadow: 1px 8px 20px grey;
	}
}

.small-card {
	width: 200px;
	margin-right: 20px;
	.item-img {
		width: 200px;
	}
}
.default-card {
	width: 300px;
	.item-img {
		width: 300px;
	}
}

@media (max-width: 1000px) {
	.item-card {
		width: 200px;
		.item-img { width: 200px; }
	}
}

@media (max-width: 800px) {
	.item-card {
		width: 250px;
		.item-img { width: 250px; }
	}
}
@media (max-width: 550px) {
	.small-card {
		width: 200px;
		.item-img {
			width: 200px;
		}
	}
	.default-card {
		width: 100%;
		.item-img {
			width: 100%;
		}
	}
}