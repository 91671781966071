.wrap-tv, .wrap-movies {
	margin-top: 40px;
	margin-bottom: 40px;

	.category {
		font-size: 20px;
		font-weight: 500;
	}

	.slider {
		display: flex;
		width: 100%;
		padding-top: 10px;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.wrap-movies {
	margin-top: 0;
}

.person-container {
	.img-container {
		img {max-width: 100%;}
	}
}