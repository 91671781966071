.wrap-nav {
	display: flex;
	align-items: center;
	a, .nav {
		cursor: pointer;
		color: black;
		text-decoration: none;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: #6366f1;
			bottom: -23px;
			left: 0;
			transform-origin: right;
			transform: scaleX(0);
			transition: transform .3s ease-in-out;
		}
		&:hover, &:active {
			color: #6366f1 !important;
			&:before {
				transform-origin: left;
				transform: scaleX(1);
			}
		}
	}
	div {
		margin-right: 20px;
		&:last-of-type {
			margin: 0;
		}
	}
	.wrap-movies-dropdown {
		@extend .nav-dropdown
	}
	.wrap-tv-shows-dropdown {
		@extend .nav-dropdown
	}
	.wrap-regions-dropdown {
		@extend .nav-dropdown
	}
	.wrap-languages-dropdown {
		@extend .nav-dropdown
	}
}

.nav-dropdown {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.inside-dropdown {
		position: absolute;
		background-color: #fff;
		top: 40px;
		width: 200px;
		max-height: 80vh;
		display: block;
		box-shadow: 0px 4px 35px #a8acb030;
		z-index: 10;
		opacity: 1;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		.wrap-item {
			cursor: pointer;
			display: flex;
			justify-content: center;
			text-align: left;
			text-decoration: none;
			padding: 5px 0;
			margin: 2px 0;
			transition: all 200ms linear;
			font-size: 15px;
			a, span {
				color: black;
				text-decoration: none; 
				&::before {content: none;}
			}
			&:hover {
				a, span { color: #6366f1; }
				background-color: #EEEEEE;
			}
		}
	}
}